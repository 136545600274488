import Dashboard from '@/views/approval/dashboard/routes'
import ApprovalType from '@/views/approval/approval-type/routes'
import ApprovalApprover from '@/views/approval/approval-approver/routes'

export default {
  path: 'approval',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.APPROVAL
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [Dashboard, ApprovalType, ApprovalApprover]
}
