export default {
  path: 'approval-approver',
  children: [
    {
      path: '',
      name: 'approval.approval-approver',
      component: () => import('./list/ApprovalApproverListPage.vue')
    },
    {
      path: ':uuid',
      name: 'approval.approval-approver.detail',
      component: () => import('./form/ApprovalApproverFormPage.vue')
    }
  ]
}
