export default {
  path: 'operation',
  children: [
    {
      path: '',
      name: 'costing.operation',
      component: () => import('./list/OperationListPage.vue')
    },
    {
      path: 'add',
      name: 'costing.operation.add',
      component: () => import('./form/OperationFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'costing.operation.edit',
      component: () => import('./form/OperationFormPage.vue')
    }
  ]
}
