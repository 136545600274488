export default {
  path: 'overtime',
  children: [
    {
      path: '',
      name: 'employee.overtime',
      component: () => import('./list/OvertimeListPage.vue')
    },
    {
      path: 'add',
      name: 'employee.overtime.add',
      component: () => import('./form/OvertimeFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'employee.overtime.edit',
      component: () => import('./form/OvertimeFormPage.vue')
    },
    {
      path: 'print/:uuid',
      name: 'employee.overtime.print',
      component: () => import('./print/OvertimePrintPage.vue')
    },
    {
      path: 'validate/:uuid',
      name: 'employee.overtime.validate',
      component: () => import('./validate/OvertimeValidatePage.vue')
    }
  ]
}
