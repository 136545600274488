export default [
  {
    path: 'part-template',
    name: 'part-template',
    component: () => import('./list/PartTemplateListPage.vue')
  },
  {
    path: 'part-template/:id',
    name: 'part-template.detail',
    component: () => import('./detail/PartTemplateDetailPage.vue')
  }
]
