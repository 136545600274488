export default {
  path: 'approval-type',
  children: [
    {
      path: '',
      name: 'approval.approval-type',
      component: () => import('./list/ApprovalTypeListPage.vue')
    },
    {
      path: 'add',
      name: 'approval.approval-type.add',
      component: () => import('./form/ApprovalTypeFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'approval.approval-type.edit',
      component: () => import('./form/ApprovalTypeFormPage.vue')
    }
  ]
}
