export default {
  path: 'part-group',
  children: [
    {
      path: '',
      name: 'costing.part-group',
      component: () => import('./list/PartGroupListPage.vue')
    }
  ]
}
