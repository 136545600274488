import Dashboard from '@/views/employee/dashboard/routes'
import Overtime from '@/views/employee/overtime/routes'

export default {
  path: 'employee',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.EMPLOYEE
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [Dashboard, Overtime]
}
