export default {
  path: 'material',
  children: [
    {
      path: '',
      name: 'costing.material',
      component: () => import('./list/MaterialListPageV2.vue')
    },
    {
      path: 'import',
      name: 'costing.material.import',
      component: () => import('./import/MaterialImportPage.vue')
    },
    {
      path: 'bulk-update',
      name: 'costing.material.bulk-update',
      component: () => import('./bulk-update/BulkUpdatePage.vue')
    }
  ]
}
