import axios from 'axios'

const PATH_URL = import.meta.env.VITE_API_BASE_URL

const axiosApp = axios.create({
  baseURL: PATH_URL
})

/**
 * Set token on http request
 * @param {String} token
 */
const setTokenAxiosApp = (token: string) => {
  axiosApp.defaults.headers.common.Authorization = `Bearer ${token}`
  window.localStorage.setItem('AUTH.TOKEN', token)
}

/**
 * Remove token from http request
 */
const removeTokenAxiosApp = () => {
  axiosApp.defaults.headers.common.Authorization = null
}

export { axiosApp, setTokenAxiosApp, removeTokenAxiosApp }
