import BoxMaterial from '@/views/costing/box-material/routes'
import BoxAccessory from '@/views/costing/box-accessory/routes'

export default {
  path: 'box/:boxId',
  children: [
    {
      path: '',
      name: 'box.detail',
      component: () => import('./detail/BoxDetailPage.vue')
    },
    BoxMaterial,
    BoxAccessory
  ]
}
