import { ElMessage } from 'element-plus'
import * as Sentry from '@sentry/browser'

/**
 * UseMessage composable function.
 * Provides methods to display success and error messages.
 */
export default function useMessage() {
  /**
   * Displays a success message.
   * @param message - The message to display. Defaults to 'Data Saved'.
   */
  function messageSuccess(message: string = 'Data Saved'): void {
    ElMessage({
      message,
      type: 'success'
    })
  }

  /**
   * Displays an error message.
   * @param error - The error to display. Can be a string or an object with a response.data.message property.
   */
  function messageError(
    error: any | Error | string | { response?: { data?: { message?: string } } }
  ): void {
    let temp = ''

    if (typeof error === 'string') {
      temp = error
    } else if (error?.response?.data?.message) {
      temp = error.response.data.message
    } else {
      temp = error.message

      // Send error to Sentry
      Sentry.captureException(error)
    }

    console.error('error', error)
    ElMessage.error(temp)
  }

  return {
    messageSuccess,
    messageError
  }
}
