export default {
  path: 'product-category',
  children: [
    {
      path: '',
      name: 'retail.product-category',
      component: () => import('./list/ProductCategoryListPage.vue')
    },
    {
      path: 'add',
      name: 'retail.product-category.add',
      component: () => import('./form/ProductCategoryFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'retail.product-category.edit',
      component: () => import('./form/ProductCategoryFormPage.vue')
    }
  ]
}
