export default {
  path: 'job-position',
  children: [
    {
      path: '',
      name: 'recruitment.job-position',
      component: () => import('./list/JobPositionListPage.vue')
    }
  ]
}
