export default {
  path: 'product-base',
  children: [
    {
      path: '',
      name: 'product-base',
      component: () => import('./list/ProductBaseListPage.vue')
    },
    {
      path: ':productBaseUuid',
      name: 'product-base.detail',
      component: () => import('./detail/ProductBaseDetailPage.vue')
    }
  ]
}
