export default {
  path: 'modifier',
  children: [
    {
      path: '',
      name: 'retail.modifier',
      component: () => import('./list/ModifierListPage.vue')
    },
    {
      path: 'add',
      name: 'retail.modifier.add',
      component: () => import('./form/ModifierFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'retail.modifier.edit',
      component: () => import('./form/ModifierFormPage.vue')
    }
  ]
}
