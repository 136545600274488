export default {
  path: 'applicant-test',
  children: [
    {
      path: '',
      name: 'recruitment.applicant-test',
      component: () => import('./list/ApplicantTestListPage.vue')
    }
  ]
}
