import Box from '@/views/costing/box/routes'

export default {
  path: 'product-version/:uuid',
  component: () => import('./detail/ProductDetailContainer.vue'),
  children: [
    {
      path: '',
      name: 'product.detail',
      component: () => import('./detail/ProductDetailPage.vue')
    },
    Box
  ]
}
