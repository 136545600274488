export default {
  path: 'box-accessory',
  children: [
    {
      path: 'add',
      name: 'costing.box-accessory.add',
      component: () => import('./form/BoxAccessoryFormPage.vue')
    },
    {
      path: 'edit/:boxMaterialId',
      name: 'costing.box-accessory.edit',
      component: () => import('./form/BoxAccessoryFormPage.vue')
    }
  ]
}
