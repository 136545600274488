import Dashboard from '@/views/dashboard/dashboard/routes'

export default {
  path: 'dashboard',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.DASHBOARD
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [Dashboard]
}
