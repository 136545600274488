export default {
  path: 'dasboard',
  children: [
    {
      path: '',
      name: 'retail.dashboard',
      component: () => import('./main/DasboardPage.vue')
    }
  ]
}
