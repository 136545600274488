export default {
  path: 'dasboard',
  children: [
    {
      path: '',
      name: 'approval.dashboard',
      component: () => import('./DasboardPage.vue')
    }
  ]
}
