import Role from './role/routes'
import User from './user/routes'

export default {
  path: 'setting',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.SETTING
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [Role, User]
}
