import CurrencyExchange from '@/views/costing/currency-exchange/routes'
import LaborCost from '@/views/costing/labor-cost/routes'
import Master from '@/views/costing/master/routes'
import Material from '@/views/costing/material/routes'
import MaterialSet from '@/views/costing/material-set/routes'
import Operation from '@/views/costing/operation/routes'
import Part from '@/views/costing/part/routes'
import PartBase from '@/views/costing/part-base/routes'
import PartSet from '@/views/costing/part-set/routes'
import PartTemplate from '@/views/costing/part-template/routes'
import Product from '@/views/costing/product/routes'
import ProductBase from '@/views/costing/product-base/routes'
import PartGroup from '@/views/costing/part-group/routes'

export default {
  path: 'costing',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.COSTING
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [
    ...CurrencyExchange,
    ...LaborCost,
    ...MaterialSet,
    ...Part,
    ...PartBase,
    ...PartSet,
    ...PartTemplate,
    Master,
    Material,
    Operation,
    Product,
    ProductBase,
    PartGroup
  ]
}
