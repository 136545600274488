import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import { version } from '../package.json'

// Tailwind CSS
import './styles/tailwind/index.css'
// Element Plus
import './styles/element/index.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'
import router from './router'

import './styles/index.scss'

const app = createApp(App)

app.use(ElementPlus)
app.use(createPinia())
app.use(router)

if (import.meta.env.VITE_NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_NODE_ENV,
    release: 'memory-cms@' + version,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          import.meta.env.VITE_SENTRY_TARGET
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.VITE_NODE_ENV === 'production' ? 0.1 : 1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate:
      import.meta.env.VITE_NODE_ENV === 'production' ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

window.addEventListener('vite:preloadError', () => {
  console.log('New content is available; Auto refresh.')

  window.location.reload() // for example, refresh the page
})

app.mount('#app')
