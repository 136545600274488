export default {
  path: 'applicant',
  children: [
    {
      path: '',
      name: 'recruitment.applicant',
      component: () => import('./list/ApplicantListPage.vue')
    },
    {
      path: 'kanban',
      name: 'recruitment.applicant.kanban',
      component: () => import('./kanban/ApplicantKanbanPage.vue')
    },
    {
      path: ':uuid',
      component: () => import('./detail/ApplicantDetailPage.vue'),
      children: [
        {
          path: 'general',
          name: 'recruitment.applicant.detail',
          component: () =>
            import(
              '@/views/recruitment/applicant/detail/general/ApplicantGeneral.vue'
            )
        },
        {
          path: 'test',
          name: 'recruitment.applicant.test',
          component: () =>
            import(
              '@/views/recruitment/applicant-test/list/ApplicantTestListCard.vue'
            )
        },
        {
          path: 'checklist',
          name: 'recruitment.applicant.checklist',
          component: () =>
            import(
              '@/views/recruitment/applicant-checklist/form/ApplicantChecklistFormCard.vue'
            )
        },
        {
          path: 'interview',
          name: 'recruitment.applicant.interview',
          component: () =>
            import(
              '@/views/recruitment/applicant-interview/list/ApplicantInterviewListCard.vue'
            )
        }
      ]
    }
  ]
}
