export default {
  path: 'dasboard',
  children: [
    {
      path: '',
      name: 'dashboard.dashboard',
      component: () => import('./DasboardPage.vue')
    }
  ]
}
