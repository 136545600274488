export default {
  path: 'test',
  children: [
    {
      path: '',
      name: 'recruitment.test',
      component: () => import('./list/TestListPage.vue')
    },
    {
      path: ':uuid',
      name: 'recruitment.test.detail',
      component: () => import('./detail/TestDetailPage.vue')
    }
  ]
}
