export default {
  path: 'box-material',
  children: [
    {
      path: 'add',
      name: 'costing.box-material.add',
      component: () => import('./form/BoxMaterialFormPage.vue')
    },
    {
      path: 'edit/:boxMaterialId',
      name: 'costing.box-material.edit',
      component: () => import('./form/BoxMaterialFormPage.vue')
    }
  ]
}
