export default {
  path: 'reset-password',
  children: [
    {
      path: '',
      name: 'open.reset-password',
      component: () => import('./form/ResetPasswordPage.vue')
    }
  ]
}
