export default {
  path: 'user',
  children: [
    {
      path: '',
      name: 'setting.user',
      component: () => import('./list/UserListPage.vue')
    },
    {
      path: 'edit/:id',
      name: 'setting.user.edit',
      component: () => import('./form/UserFormPage.vue')
    },
    {
      path: 'invite',
      name: 'setting.user.invite',
      component: () => import('./form-invite/UserInviteFormPage.vue')
    }
  ]
}
