export default {
  path: 'invite',
  children: [
    {
      path: 'register',
      name: 'open.invite.register',
      component: () => import('./register/RegisterPage.vue')
    }
  ]
}
