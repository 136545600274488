import Dashboard from '@/views/recruitment/dashboard/routes'
import Applicant from '@/views/recruitment/applicant/routes'
import ApplicantTest from '@/views/recruitment/applicant-test/routes'
import Test from '@/views/recruitment/test/routes'
import JobPosition from '@/views/recruitment/job-position/routes'

export default {
  path: 'recruitment',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.RECRUITMENT
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [Dashboard, Test, Applicant, ApplicantTest, JobPosition]
}
