export default {
  path: 'role',
  children: [
    {
      path: '',
      name: 'setting.role',
      component: () => import('./list/RoleListPage.vue')
    },
    {
      path: 'add',
      name: 'setting.role.add',
      component: () => import('./form/RoleFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'setting.role.edit',
      component: () => import('./form/RoleFormPage.vue')
    }
  ]
}
