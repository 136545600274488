export default {
  path: 'pendaftaran',
  children: [
    {
      path: 'formulir/:uuid',
      name: 'recruitment-open.applicant-form',
      component: () => import('./applicant-form/ApplicantFormPage.vue')
    },
    {
      path: 'soal-tes',
      name: 'recruitment-open.test-code',
      component: () => import('./test-code/TestCodePage.vue')
    },
    {
      path: 'soal-tes/:uuid',
      name: 'recruitment-open.test',
      component: () => import('./test/TestPage.vue')
    },
    {
      path: 'interview',
      name: 'recruitment-open.interview-code',
      component: () => import('./interview-code/InterviewCodePage.vue')
    },
    {
      path: 'interview-tes/:uuid',
      name: 'recruitment-open.interview',
      component: () => import('./interview/InterviewPage.vue')
    }
  ]
}
