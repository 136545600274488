export default {
  path: 'store',
  children: [
    {
      path: '',
      name: 'retail.store',
      component: () => import('./list/StoreListPage.vue')
    },
    {
      path: 'add',
      name: 'retail.store.add',
      component: () => import('./form/StoreFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'retail.store.edit',
      component: () => import('./form/StoreFormPage.vue')
    }
  ]
}
