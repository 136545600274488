<template>
  <el-form label-position="top" class="container-login p-4">
    <!-- Form -->
    <div
      class="flex min-w-full max-w-xl flex-col gap-4 rounded-lg bg-white p-4 sm:min-w-96"
    >
      <div class="flex flex-col items-center gap-2">
        <div class="text-2xl font-bold">Log in to CMS</div>
        <div class="text-sm text-slate-500">
          Welcome back! Please sign in to continue
        </div>
      </div>

      <div class="flex flex-col gap-y-1">
        <el-form-item label="Email" :error="getErrors(v$.email.$errors)">
          <el-input
            v-model="v$.email.$model"
            placeholder="Please input Email"
          />
        </el-form-item>

        <el-form-item label="Password" :error="getErrors(v$.password.$errors)">
          <el-input
            v-model="v$.password.$model"
            placeholder="Please input Password"
            type="password"
            show-password
          />
        </el-form-item>
      </div>

      <div class="mt-4">
        <el-button
          class="w-[100%]"
          size="large"
          type="primary"
          :loading="loading.form"
          @click="handleSave"
          >Sign in</el-button
        >
      </div>
    </div>
  </el-form>
</template>

<script lang="ts" setup>
import {
  required,
  minLength,
  maxLength,
  helpers,
  email
} from '@vuelidate/validators'

import { axiosApp, setTokenAxiosApp } from '@/utils/axios'

const router = useRouter()
const { getErrors } = useFilter()
const { messageSuccess, messageError } = useMessage()

interface IForm {
  email: string
  password: string
}

const form = reactive<IForm>({
  email: '',
  password: ''
})

interface ILoading {
  form: boolean
}

const loading: ILoading = reactive({
  form: false
})

const noSpaces = helpers.withMessage(
  'Value cannot contain spaces',
  (value) => !/\s/.test(value as string)
)

const rules = computed(() => {
  const rulesTemp = {
    email: {
      required,
      maxLength: maxLength(255),
      email
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(255),
      noSpaces: noSpaces
    }
  } as any

  return rulesTemp
})

const v$ = useVuelidate(rules, form)

const handleSave = async () => {
  v$.value.$validate()

  if (v$.value.$error) {
    return
  }

  try {
    loading.form = true

    const payload = {
      email: form.email,
      password: form.password
    }

    const {
      data: {
        result: { token }
      }
    } = await axiosApp.post(API.CMS.POST_LOGIN, payload)

    messageSuccess('Login successfully.')

    // Validate token
    setTokenAxiosApp(token)

    // Retrieve the redirect URL from the query parameters
    const redirectUrl = router.currentRoute.value.query.redirect as string

    if (redirectUrl) {
      // Redirect to the stored URL
      router.replace(redirectUrl)
    } else {
      // Redirect to a default route if no redirect URL is found
      router.replace({ name: 'home' })
    }
  } catch (error: any) {
    messageError(error)
  } finally {
    loading.form = false
  }
}
</script>

<style lang="scss" scoped>
.container-login {
  @apply flex min-h-svh items-center justify-center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #e7e9ed;
  background-image: url('@/views/home/assets/background-light.svg');
}
</style>
