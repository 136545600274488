const CONFIG = {
  FORM: {
    DIALOG_SIZE: {
      ONE_COL: 500,
      TWO_COL: 800,
      THREE_COL: 1100
    },
    LABEL_WIDTH: {
      SHORT: 120,
      MEDIUM: 160,
      LONG: 200
    },
    VALIDATION: {
      REQUIRED: {
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      },
      MAX: {
        max: 255,
        message: 'This field is too long',
        trigger: 'blur'
      },
      NUMBER_REQUIRED: {
        type: 'number',
        required: true,
        message: 'This field is required',
        trigger: 'blur'
      }
    }
  },
  MODULE_NAMES: [
    'costing',
    'retail',
    'employee',
    'recruitment',
    'setting',
    'dashboard'
  ],
  MODULE_GROUP: {
    SETTING: 'setting',
    DASHBOARD: 'dashboard',
    RETAIL: 'retail',
    EMPLOYEE: 'employee',
    RECRUITMENT: 'recruitment',
    COSTING: 'costing',
    APPROVAL: 'approval'
  },
  MODULE_GROUPS: [
    {
      label: 'Costing',
      value: 'costing'
    },
    {
      label: 'Retail',
      value: 'retail'
    },
    {
      label: 'Employee',
      value: 'employee'
    },
    {
      label: 'Recruitment',
      value: 'recruitment'
    },
    {
      label: 'Settings',
      value: 'settings'
    },
    {
      label: 'Dashboard',
      value: 'dashboard'
    }
  ],
  RATE_COLORS: ['#99A9BF', '#F7BA2A', '#FF9900'],
  ENUM: {
    BOX: {
      TYPE: ['SW', 'DW'],
      VARIANT: ['B1', 'B5.5', 'TOP BOTTOM']
    },
    PART: {
      TYPE: ['Solid', 'Hollow'],
      FACTORY_TYPE: ['Panel', 'Soft', 'Curve', 'MECO Profile', 'MECO Straight'],
      LAMINATION_FACE: ['None', 'SF', 'DF'],
      LAMINATION_SIDE: ['X', 'Y'],
      EDGE_TYPE: [
        'Auto',
        'Manual',
        'Foil Straight Auto',
        'Foil Round Auto',
        'Foil Manual'
      ]
    },
    CYCLE_TYPES: [
      {
        id: 'SECOND_PER_OUTPUT',
        name: 'Second per Output'
      },
      {
        id: 'OUTPUT_PER_MINUTE',
        name: 'Output per Minute'
      }
    ],
    APPROVAL: {
      APPROVAL_TYPES: [
        {
          id: 'OVERTIME',
          name: 'Overtime'
        }
      ]
    }
  }
}

export default CONFIG
